<template>
    <div class="bg-gradient-to-br-[#8a1538] bg-[url('https://cdn.discordapp.com/attachments/1039274843029377076/1055478368780431371/1288612.jpg?width=1288&height=671')] bg-cover bg-no-repeat bg-center min-h-[calc(100vh_-_76px)] bg-cover w-full">

        <div class="flex flex-col justify-center w-full absolute mt-20 items-center">
            <h1 class="font-semibold bg-amber-50 p-2 rounded w-1/3">Finale de la coupe du monde le 18 Décembre 2022 au stade Lusail</h1>
            <router-link to="/carte" class="rounded-[10px] bg-[#bd2553] bg-opacity-100 border-[1px] border-[#a31540] border-amber-50 text-amber-50 px-3 py-2 mt-2 duration-300 hover:bg-opacity-80">Accéder à la carte intéractive</router-link>
        </div>
        <div class="flex min-h-[calc(100vh_-_76px)] flex-col w-full items-center justify-center	">

            <div class="flex flex-row items-center justify-evenly w-full">
                <img src="https://api.cdm2022.site/images/drapeaux/AR.svg" alt="argentine" class="vainqueur h-48 rounded">
                <div class="flex flex-col text-amber-50 bg-[#bd2553] py-3 px-10 rounded bg-opacity-90">
                    <div class="text-6xl p-2 w-full"> 3 &nbsp; - &nbsp; 3 </div>
                    <div class="text-3xl p-2 w-full"><b>4</b> - 2 <br><h6 class="text-sm">Tirs aux buts</h6></div>
                </div>
                <img src="https://api.cdm2022.site/images/drapeaux/FR.svg" alt="france" class="h-48 rounded">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageAccueil"
}
</script>

<style scoped>
.vainqueur{
    animation: border-pulse 1.5s infinite;
}

@keyframes border-pulse {
    0% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 1); }
    70% { box-shadow: 0 0 0 25px rgba(255, 0, 0, 0); }
    100% { box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);  }
}
</style>