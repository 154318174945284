<template>
    Déconnexion en cours...
</template>

<script>
//import router from "@/router";

export default {
    name: "DeconnexionElt",
    beforeMount() {
        sessionStorage.clear();
        //router.push('connexion');
        window.location.href = "/connexion";
    }
}
</script>

<style scoped>

</style>