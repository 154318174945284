<template>
    <div class="sidebar">
        <button aria-label="close sidebar" type="button" class="close-button">
            <i class="fa-solid fa-x text-white"></i>
        </button>
    </div>
    <div class="border-gray-900" id="mapContainer"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import 'leaflet.markercluster';
import {closeSidebar, showSidebarWidthText, showSidebarWidthTextPays} from "../assets/js/popupMap.js" ;
import $ from "jquery";
// async function to get data from json
async function fetchData(url) {
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (err) {
        console.error(err);
    }
}

export default {
    name: "CarteLeaflet",

    data() {
        return {
            map: null,
        };
    },
    mounted() {
        var lat = 5.17848;
        var lng = 15.82031;
        var zoom = 3;
        this.map = L.map("mapContainer", {
            zoomControl: true, zoomAnimation: false, fadeAnimation: true, markerZoomAnimation: true, minZoom: 3,
            maxZoom: 18,
        }).setView([lat, lng], zoom);

        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(this.map);
        var markersStades = [];
        var stades = [];
        var clusterStade = L.markerClusterGroup();

        /* Bouton qui centre sur les stades */
        const htmlTemplate =
            '<i class="fa-brands fa-fort-awesome-alt text-xl"></i>';
        const customControl = L.Control.extend({
            // button position
            options: {
                position: "topleft",
            },

            onAdd: function () {
                const btn = L.DomUtil.create("button");
                btn.title = "Centrer sur les stades";
                btn.innerHTML = htmlTemplate;
                btn.className += "leaflet-bar back-to-stade hidden w-[34px] h-[34px] bg-white hover:cursor-pointer";
                return btn;
            },
        });

        this.map.addControl(new customControl());

        const buttonBackToStade = document.querySelector(".back-to-stade");
        this.map.on("moveend", goToMap(this.map, buttonBackToStade, [25.205031, 51.17981], 8));

        /* Bouton qui centre sur la carte */
        const htmlTemplateStade =
            '<i class="fa-solid fa-earth-americas text-xl"></i>';
        const customStadeControl = L.Control.extend({
            // button position
            options: {
                position: "topleft",
            },

            onAdd: function () {
                const btn = L.DomUtil.create("button");
                btn.title = "Recentrer la carte";
                btn.innerHTML = htmlTemplateStade;
                btn.className += "leaflet-bar back-to-map hidden w-[34px] h-[34px] bg-white hover:cursor-pointer";
                return btn;
            },
        });

        this.map.addControl(new customStadeControl());

        const buttonCenterMap = document.querySelector(".back-to-map");
        this.map.on("moveend", goToMap(this.map, buttonCenterMap,[lat, lng], zoom));

        function goToMap(map, buttonBackToHome, coords, zoom) {
            buttonBackToHome.classList.remove("hidden");

            buttonBackToHome.addEventListener("click", () => {
                console.log(coords)
                map.flyTo(coords, zoom);
            });
        }

        // --------------------------------------------------
        // button to close sidebar
        const buttonClose = document.querySelector(".close-button");
        // --------------------------------------------------
        // close when click esc
        document.addEventListener("keydown", function (event) {
            // close sidebar when press esc
            if (event.key === "Escape") {
                closeSidebar();
            }
        });

        // close sidebar when click on close button
        buttonClose.addEventListener("click", () => {
            // close sidebar when click on close button
            closeSidebar();
        });

        // close sidebar when click outside
        document.addEventListener("click", (e) => {
            const target = e.target;
            if (
                !target.closest(".sidebar") &&
                !target.classList.contains("leaflet-marker-icon") &&
                !target.classList.contains("polygon-pays")
            ) {
                closeSidebar();
            }
        });

        /* ICONES PERSONNALISÉES : */
        var iconeStade = L.icon({
            iconUrl: 'https://api.cdm2022.site/images/stade.png',
            iconSize: [32, 32],
            iconAnchor: [16, 16],
            popupAnchor: [0, -16]
        });
        var iconeStadeSurvol = L.icon({
            iconUrl: 'https://api.cdm2022.site/images/stade_survol.png',
            iconSize: [32, 32],
            iconAnchor: [16, 16],
            popupAnchor: [0, -16]
        });

        fetchData("https://api.cdm2022.site/getAllStades")
            .then((data) => {
                // create markers width "marker-options-id"
                data.lst_stades.map((marker) => {
                    stades.push(marker);
                    markersStades.push(
                        L.marker(marker.coordonnees.split(","), {icon: iconeStade, "marker-options-id": marker.id})
                            .bindPopup("<h3 class='text-xl text-center font-bold'>" + marker.nom + "</h3><i>Cliquez pour plus d'informations</i>", {closeButton: false})
                    );

                });

                return data.lst_stades;
            })
            .then((data) => {
                markersStades.forEach((marker) => {
                    marker.on('mouseover', function (ev) {
                        ev.target.openPopup();
                        ev.target.setIcon(iconeStadeSurvol);
                    });
                    marker.on('mouseout', function (ev) {
                        ev.target.setIcon(iconeStade);
                        ev.target.closePopup();
                    });
                    clusterStade.addLayer(marker);
                });

                // add event listener to markers to open sidebar
                clusterStade.on("click", function (e) {
                    if (e.layer instanceof L.Marker) {
                        showSidebarWidthText(e.layer.options["marker-options-id"], data, this.map, clusterStade);
                    }
                });
            });

        this.map.addLayer(clusterStade);


        /* Importation de la France et de l'Argentine */
        const urlFrance = 'https://api.cdm2022.site/geojson/france';
        const urlArgentine = 'https://api.cdm2022.site/geojson/argentine';
        ajouterPays(urlFrance, this.map);
        ajouterPays(urlArgentine, this.map);
        function ajouterPays(url, map){
            fetchData(url)
                .then((data) => {
                    const polygons = data.features;
                    const nom = polygons[0].properties.name;
                    L.geoJSON(data, {color : "#16A34A"})
                        .setStyle({"className": "polygon-pays"})
                        .bindPopup("<h3 class='text-xl text-center font-bold'>" + nom + "</h3><i>Cliquez pour plus d'informations</i>", {closeButton: false})
                        .on('mouseover', function (ev) {
                            ev.target.openPopup().setStyle({color : "#bd2553"});
                        })
                        .on('mouseout', function (ev) {
                            ev.target.closePopup().setStyle({color : "#16A34A"});
                        })
                        .on('click', function () {
                            $("body").addClass("active-sidebar");
                            document.body.classList.add("active-sidebar");
                            showSidebarWidthTextPays(nom, map);
                        }).addTo(map);
                });
        }
    },
    onBeforeUnmount() {
        if (this.map) {
            this.map.remove();
        }
    },
};
</script>

<style scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "../assets/css/mapStyle.css";
</style>