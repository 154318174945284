//import * as url from "url";

export {showSidebarWidthText, closeSidebar, showSidebarWidthTextPays};

// async function to get data from json
async function fetchData(url) {
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (err) {
        console.error(err);
    }
}

function showSidebarWidthText(id, data, map, groupBounds) {
    data.filter((marker) => {
        if (marker.id === id) {
            document.body.classList.add("active-sidebar");
            addContentToSidebar(marker, map, groupBounds);
        }
    });
}

function showSidebarWidthTextPays(nom, /*data,*/ map) {
    /*data.filter((polygon) => {
        if (polygon.properties.name === nom) {
            document.body.classList.add("active-sidebar");
            addContentToSidebarPays(nom, map);
        }
    });*/
    document.body.classList.add("active-sidebar");
    addContentToSidebarPays(nom, map);
}

function closeSidebar() {
    // remove class active-sidebar
    document.body.classList.remove("active-sidebar");

    // bounds map to default
}

function addContentToSidebar(marker) {
    const {nom, ville, description, capacite, lien_img} = marker;

    // create sidebar content
    const sidebarTemplate = `
            <article class="sidebar-content">
              <div class="bg-[#bd2553] px-5 py-3">
                <h1 class="text-white text-5xl"><b>${nom}</b></h1>
              </div>
              
              <div class="info-content justify-center">
                <img src=${lien_img} alt=${nom} class="object-contain h-max">
                
                <div class="m-3">
                        <p class="text-2xl text-center">
                            <i class="fa-solid fa-map-location-dot"></i> ${ville} <i class="fa-solid fa-users text-xl"></i> ${capacite} places
                        </p>
                        <p class="italic mt-2 text-justify">${description}</p>
                </div>
              </div>
            </article>
          `;

    const sidebar = document.querySelector(".sidebar");
    const sidebarContent = document.querySelector(".sidebar-content");

    // always remove content before adding new one
    sidebarContent?.remove();

    // add content to sidebar
    sidebar.insertAdjacentHTML("beforeend", sidebarTemplate);

    // set bounds depending on marker coords
}

function addContentToSidebarPays(nom) {
    const url = 'https://api.cdm2022.site/getJoueursPays?idPays=' + (nom === 'France' ? 13 : 9);
    console.log(url);
    var joueurs = '';
    fetchData(url)
        .then((data) => {
            console.log(data);
            data.lst_joueurs.forEach((joueur) => {
                joueurs += "<li>" + joueur.poste + " - " + joueur.nom + " - " + joueur.numero + "</li>";
            });
            const flagUrl = "https://api.cdm2022.site/images/drapeaux/" + nom.slice(0, 2).toUpperCase() + ".svg";
            console.log(flagUrl);
            const sidebarTemplate = `
            <article class="sidebar-content">
              <div class="bg-[#bd2553] px-5 py-3">
                <h1 class="text-white text-5xl"><b>${nom}</b></h1>
              </div>
              <div class="info-content justify-center text-center">
                <img src=${flagUrl} alt=${nom} class="h-40 mt-5 rounded inline">
                <div class="m-3">
                    <ul class="decoration-0">${joueurs}</ul>
                </div>
              </div>
              
            </article>
          `;
            const sidebar = document.querySelector(".sidebar");
            const sidebarContent = document.querySelector(".sidebar-content");

            // always remove content before adding new one
            sidebarContent?.remove();

            // add content to sidebar
            sidebar.insertAdjacentHTML("beforeend", sidebarTemplate);
        })

}