<template>
  <PageAccueil/>
</template>

<script>
// @ is an alias to /src
import PageAccueil from '@/components/PageAccueil'
export default {
  name: 'HomeView',
  components: {
      PageAccueil
  }
}
</script>
